'use client';

import t from '@guestyci/localize/t.macro';
import { HOUR_FORMAT_12, HOUR_FORMAT_24 } from './date';

export const LANGUAGE_CODES = {
  DE: 'de',
  EL: 'el',
  EN: 'en',
  ES: 'es',
  FR: 'fr',
  ID: 'id',
  IT: 'it',
  JA: 'ja',
  KO: 'ko',
  PL: 'pl',
  PT: 'pt',
  RO: 'ro',
  ZH: 'zh',
  NL: 'nl',
};

export const shortLanguageList = [
  LANGUAGE_CODES.DE,
  LANGUAGE_CODES.EL,
  LANGUAGE_CODES.EN,
  LANGUAGE_CODES.ES,
  LANGUAGE_CODES.FR,
  LANGUAGE_CODES.ID,
  LANGUAGE_CODES.IT,
  LANGUAGE_CODES.JA,
  LANGUAGE_CODES.KO,
  LANGUAGE_CODES.PL,
  LANGUAGE_CODES.PT,
  LANGUAGE_CODES.RO,
  LANGUAGE_CODES.ZH,
  LANGUAGE_CODES.NL,
];

export const fullCodesToShort = {
  'de-DE': LANGUAGE_CODES.DE,
  'el-GR': LANGUAGE_CODES.EL,
  'en-US': LANGUAGE_CODES.EN,
  'es-ES': LANGUAGE_CODES.ES,
  'fr-FR': LANGUAGE_CODES.FR,
  'id-ID': LANGUAGE_CODES.ID,
  'it-IT': LANGUAGE_CODES.IT,
  'ja-JP': LANGUAGE_CODES.JA,
  'ko-KR': LANGUAGE_CODES.KO,
  'pl-PL': LANGUAGE_CODES.PL,
  'pt-PT': LANGUAGE_CODES.PT,
  'ro-RO': LANGUAGE_CODES.RO,
  'zh-cmn': LANGUAGE_CODES.ZH,
  'nl-NL': LANGUAGE_CODES.NL,
};

export const languagesTimeFormats = {
  [LANGUAGE_CODES.DE]: HOUR_FORMAT_24,
  [LANGUAGE_CODES.EL]: HOUR_FORMAT_12,
  [LANGUAGE_CODES.EN]: HOUR_FORMAT_12,
  [LANGUAGE_CODES.ES]: HOUR_FORMAT_24,
  [LANGUAGE_CODES.FR]: HOUR_FORMAT_24,
  [LANGUAGE_CODES.ID]: HOUR_FORMAT_12,
  [LANGUAGE_CODES.IT]: HOUR_FORMAT_24,
  [LANGUAGE_CODES.JA]: HOUR_FORMAT_12,
  [LANGUAGE_CODES.KO]: HOUR_FORMAT_12,
  [LANGUAGE_CODES.PL]: HOUR_FORMAT_24,
  [LANGUAGE_CODES.PT]: HOUR_FORMAT_24,
  [LANGUAGE_CODES.RO]: HOUR_FORMAT_24,
  [LANGUAGE_CODES.ZH]: HOUR_FORMAT_24,
  [LANGUAGE_CODES.NL]: HOUR_FORMAT_24,
};

export const languagesToLabel = () => [
  {
    label: t('English'),
    value: LANGUAGE_CODES.EN,
  },
  {
    label: t('German'),
    value: LANGUAGE_CODES.DE,
  },
  {
    label: t('Spanish (Spain)'),
    value: LANGUAGE_CODES.ES,
  },
  {
    label: t('French'),
    value: LANGUAGE_CODES.FR,
  },
  {
    label: t('Italian'),
    value: LANGUAGE_CODES.IT,
  },
  {
    label: t('Polish'),
    value: LANGUAGE_CODES.PL,
  },
  {
    label: t('Portuguese'),
    value: LANGUAGE_CODES.PT,
  },
  {
    label: t('Chinese'),
    value: LANGUAGE_CODES.ZH,
  },
  {
    label: t('Romanian'),
    value: LANGUAGE_CODES.RO,
  },
  {
    label: t('Greek'),
    value: LANGUAGE_CODES.EL,
  },
  {
    label: t('Japanese'),
    value: LANGUAGE_CODES.JA,
  },
  {
    label: t('Korean'),
    value: LANGUAGE_CODES.KO,
  },
  {
    label: t('Indonesian'),
    value: LANGUAGE_CODES.ID,
  },
  {
    label: t('Dutch'),
    value: LANGUAGE_CODES.NL,
  },
];

export const DEFAULT_LANGUAGE = 'en';

export const countryCodeToLabel = (value) => {
  const language = languagesToLabel().find(({ value: languageValue }) => languageValue === value);
  return language?.label;
};

export const mapLanguagesToShortCodes = (languages) => {
  return languages?.map((language) => {
    return {
      ...language,
      language: fullCodesToShort[language.language],
    };
  });
};
